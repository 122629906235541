import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import { instagramUrl } from "../constants/navDetails";
import instagram from "../images/instagram_black.png";
import ConcretePage from "./services/concrete";

const content = `H-Con-Co Inc. was built on a foundation of teamwork and integrity. With over 18 years of experience set out to establish a professional concrete, masonry, framing and roofing company with goals of providing the best customer experience and delivering high quality results. Our team work is our key to success; with three specialized divisions of concrete, framing and roofing services with highly skilled field engineers in each sector.`
const content2 = `Whether we’re working on concrete high rise structures, tilt-up, or driveways, we approach your project with the goal to support your success. We reach this goal because we can provide the same expertise and service of our largest competitors while retaining the agility of a smaller company.
`;

const Container = styled.div`
  padding: 3rem 2rem 5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    color: ${props => props.theme.textGrey};
    align-self: flex-start;
    @media screen and (min-width: 768px) {
      width: 40rem;
      align-self: unset;
    }`;

const Content = styled.div`
  line-height: 1.5rem;
  width: 100%;
  > p {
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 768px) {
    width: 40rem;
  }

`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;

  line-height: 1.5rem;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    width: 40rem;
  }

  &.image-gallery-thumbnail.active {
    border-color: red;
  }

  .image-gallery-thumbnail.active {
    border-color: ${props => props.theme.bgGold} !important;
  }

  .image-gallery-left-nav {
      &:hover {
          &:before {
              color: ${props => props.theme.bgGold} !important;
            }
      }
    }

    .image-gallery-right-nav {
        &:hover {
            &:before {
                color: ${props => props.theme.bgGold} !important;
            }
        }
    }

`;


const Icon = styled.img`
    width: 3rem;
    height: auto;
    padding: 1rem 1rem 1rem 0;
`;

const seoContent = {
  seo: {
  siteUrl: 'https://hconcoinc.com/about',
  title: "About us",
  keywords: [`concrete estimate`, `about hconco`, `concrete`, `hconco`, `nashville`],
  description: 'H-Con-Co Inc of Nashville, TN was built on a foundation of teamwork and integrity. With over 18 years of experience set out to establish a professional concrete company with goals of providing the best customer experience and delivering high quality results'
  }
}

const AboutPage = () => (
  <Layout>
    <SEO title={seoContent.seo.title} keywords={seoContent.seo.keywords} description={seoContent.seo.description} siteUrl={seoContent.seo.siteUrl}/>
    <Container>
      <Title>About Us</Title>
      <Content>
        <p>{content}</p>
        <p>{content2}</p>
        <ImageContainer>
          <a href={instagramUrl} target="_blank"><Icon src={instagram} /></a>
          <a href={instagramUrl} target="_blank">Follow us on Instagram @hconcoinc</a>
        </ImageContainer>
      </Content>
    </Container>

  </Layout>
)

export default AboutPage;
